<template>
    <v-container fluid>
     <v-row>
        <v-col>
            <div class="title-container">
              <h1 class="h1">Gestão das Notas Fiscais</h1>
              <v-tooltip bottom v-if="notasFiscaisNaoAnalisadas > 0">
                  <template v-slot:activator="{ on }">
                      <span v-on="on" class="badge"> {{ notasFiscaisNaoAnalisadas }} </span>
                  </template>
                  <span>Existe(m) {{ notasFiscaisNaoAnalisadas }} nota(s) fiscal(is) pendente(s) de avaliação</span>
              </v-tooltip>
          </div>
        </v-col>
    </v-row>
      <p></p>
        <v-card-title>
            <v-row class="mt-2" >
                <v-col >
                    <!-- Listar todas as Notas Fiscais na tela, seguindo a rodem definida dentro do médodo get_Notas_Fiscais 
                    Primeiro as que ainda nõa foram analisadas e depois por ordem decrescente de ID-->
                    <v-data-table :loading="loading"  :items="items"  :notasFiscais="notasFiscais" :search="search"  :headers="headers" >
                        <!-- Campo de pesquisa  -->
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="2">
                                    <v-select :loading="loading" label="Competência Inicial" dense outlined v-model="selectedCompetenciaInicial" :items="competencias" clearable />
                                </v-col>
                                <v-col cols="2">
                                    <v-select :loading="loading" label="Competência Final" dense outlined v-model="selectedCompetenciaFinal" :items="competencias" clearable />
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field label="Pesquisar" dense  outlined v-model="search" class="mx-0" append-icon="mdi-magnify" clearable
                                    />
                                </v-col>
                                <v-col cols="5" class="text-right">
                                     <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="elevation-0 ml-5" @click="exportToExcel" v-bind="attrs" v-on="on" style="background-color: transparent;">
                                            <v-icon color="green" size="28">mdi-file-excel</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Exportar para Excel</span>
                                    </v-tooltip>
                                    <v-menu eager offset-x left v-model="menu" content-class="menu-content">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                                                mdi-printer
                                            </v-icon>
                                        </template>
                                        <v-container class="container">
                                            <RelatorioNotasFiscaisEquipe :dados="items" :compinicial="selectedCompetenciaInicial" :compfinal="selectedCompetenciaFinal"></RelatorioNotasFiscaisEquipe>
                                        </v-container>
                                    </v-menu>
                                    <v-btn color="red" text class="mr-2" elevation="0" @click="clearData">Limpar</v-btn>
                                    <v-btn color="gree" class="mr-2" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon> Atualizar</v-btn>
                                    <v-btn color="success" class="mr-2" elevation="0" @click="validateAndSearch">Pesquisar</v-btn>
                                </v-col>
                                <v-col class="text-right">

                                </v-col>
                            </v-row>
                        </template>
                        <!-- Customizando a exibição do campo competência -->
                        <template v-slot:[`item.competencia`]="{ item }">
                            {{ formatCompetencia(item.competencia) }}
                        </template>

                        <!-- Customizando a exibição do valor da nota fiscal -->
                        <template v-slot:[`item.valor_nf`]="{ item }">
                            {{ formatCurrency(item.valor_nf) }}
                        </template>
                        <!-- Customizando a exibição do valor da nota fiscal antecipada -->
                        <template v-slot:[`item.valor_antecipacao_nf`]="{ item }">
                            {{ item.valor_antecipacao_nf ? formatCurrency(item.valor_antecipacao_nf) : '' }}
                        </template>
                        <!-- Mudando a forma de mostrar na tela as palavras FALSE e TRUE por NÃO e SIM -->
                        <template v-slot:[`item.antecipacao`]="{ item }">
                            <span :class="{'text-green': item.antecipacao, 'text-red': !item.antecipacao}">
                                {{ formatAntecipacao(item.antecipacao) }}
                            </span>
                        </template>
                        <template v-slot:[`item.aceite`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="green" v-if="item.aceite === true">mdi-check-circle-outline</v-icon>
                            <v-icon v-bind="attrs" v-on="on" color="red" v-else-if="item.aceite === false">mdi-close-circle-outline</v-icon>
                            </template>
                            <span>{{ item.aceite === true ? 'Aprovado' : 'Reprovado' }}</span>
                        </v-tooltip>
                        </template>

                        <!-- Código para mostrar o arquivo da Nota Fiscal -->
                        <template v-slot:[`item.arquivo`]="{ item }">
                                <v-icon v-if="item.arquivo" @click="downloadDocumento(item.arquivo)">mdi-file</v-icon>
                        </template>
                        <!-- Código para mostrar o arquivo XML-->
                        <template v-slot:[`item.xml`]="{ item }">
                                <v-icon v-if="item.xml" @click="downloadDocumento(item.xml)">mdi-xml</v-icon>
                        </template>
                        <!-- Onde editaremos uma linha espécifica selecioanda-->
                        <template v-slot:[`item.editar`]="{ item }">
                            <v-btn icon @click="editItem(item)">
                                <v-icon color="green">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-title>
        <!-- Modal de edição -->
        <v-dialog v-model="dialog" max-width="800px">
            <v-card>
                <v-card-title style="display: flex; flex-direction: column; align-items: center; position: relative;">
                <span class="text-h6" style="color: #396A9F; margin-bottom: 10px;">Análise de Nota Fiscal</span>
                <span v-if="editedItem.prestador" style="color: #396A9F;">
                    <strong>Prestador:</strong> {{ editedItem.prestador.apelido }}
                    <strong> - Competência:</strong> {{ formatCompetencia(editedItem.competencia) }}
                </span>
                <v-btn icon style="position: absolute; top: 0; right: 0;" @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container fluid>
                        <v-row >
                        <v-col cols="3" class="d-flex align-center justify-center">
                            <v-radio-group v-model="editedItem.aceite">
                                <span>Selecione:</span>
                                <v-radio label="Aprovado" :value="true"></v-radio>
                                <v-radio label="Reprovado" :value="false"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="9">
                            <div class="textarea-wrap">
                                <v-text-field v-model="editedItem.obs" label="Observação para o Prestador:" auto-grow></v-text-field>
                            </div>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn rounded color="red" dark @click="close" class="mr-10">Cancelar</v-btn>
                    <v-btn rounded color="green" dark @click="confirmSave" class="ml-4">Salvar</v-btn>
                </v-card-actions>
                <v-dialog v-model="confirmDialog" max-width="600">
                    <v-card>
                    <v-card-title class="headline">Confirmação</v-card-title>
                    <v-card-text>
                        <p>Tem certeza de que deseja salvar?</p>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn color="red" dark @click="cancelSave" class="mr-10">Não</v-btn>
                        <v-btn color="green" dark @click="save" class="ml-4">Sim</v-btn>
                    </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import api from '../../http';
import moment from 'moment';
import '../../assets/css/styles.css';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default {
  name:"Notafiscal",
  components: {
    RelatorioNotasFiscaisEquipe: () => import('../relatorios/RelatorioNotasFiscaisEquipe.vue'),
  },
  data: () => ({
    selectedCompetenciaInicial: null,
    selectedCompetenciaFinal: null,
    loading: false,
    items: [],
    notasFiscais: [],
    search: '',
    competencias:[],
    selectedCompetencia: null,
    shouldLoadNotasFiscais: false,
    notasFiscaisNaoAnalisadas: 0,
    menu: false,
    headers: [
        { text: "ID", sortable: true,value: "id",  },
        { text: "Prestador", sortable: true, width: 200, value: "prestador.apelido" },
        { text: "Especialidade", sortable: true, width: 150, value: "prestador.especialidade.nome" },
        { text: "Competência", sortable: true, align: "center", value: "competencia", type:"date" },
        { text: "Valor N.F.", sortable: true, width: 120, value: "valor_nf" },
        { text: "Antecipação? ", sortable: true, align: "center", value: "antecipacao" },
        { text: "Valor N.F. com antecipada ", sortable: true, width: 120, value: "valor_antecipacao_nf" },
        { text: "Arquivo N.F..", sortable: true, align: "center", value: "arquivo" },
        { text: "Arquivo XML", sortable: true, align: "center", value: "xml" },
        { text: "Aprovado? ", sortable: true, align: "center", value: "aceite" },
        { text: "OBS ", sortable: true, width: 150,value: "obs" },
        { text: "Analisar", sortable: false, align: "center", width: 80, value: "editar" },
      ],
      dialog: false,
      editedItem: {  
          aceite: '',
          obs: '',
      },
      confirmDialog: false,
  }),
  methods: {
    validateAndSearch() {
      if (!this.selectedCompetenciaInicial || !this.selectedCompetenciaFinal) {
        this.$toast.error("Por favor, selecione o mês de competência inicial e final para pesquisar.");
        return;
      }
      this.get_NotasFiscais();
    },
    // Método para carregar apenas as competências
    async loadCompetencias() {
        this.loading = true;
        try {
            const response = await api.get('notas_fiscais/notas_fiscais/');
            let allItems = response.data;
            // Formata as competências e as ordena em ordem decrescente
            this.competencias = [...new Set(allItems.map(item => moment(item.competencia).format('MM/YYYY')))]
                .sort((a, b) => moment(b, 'MM/YYYY').diff(moment(a, 'MM/YYYY')));
            // Define automaticamente as competências inicial e final
            if (this.competencias.length > 0) {
                this.selectedCompetenciaFinal = this.competencias[0];
                // Tenta definir a competência inicial para duas antes da mais recente, se disponível
                const indexInicial = this.competencias.length >= 3 ? 3 : this.competencias.length - 1;
                this.selectedCompetenciaInicial = this.competencias[indexInicial];
            }
        } catch (error) {
            console.log(error);
            this.$toast.error(`Erro ao carregar competências. ${error}`);
        } finally {
            this.loading = false;
        }
    },
      // Método para listar todas as notas fiscais
    async get_NotasFiscais() {
        this.loading = true;
        try {
            const response = await api.get('notas_fiscais/notas_fiscais/');
            let allItems = response.data;
            // console.log('Notas Fiscais --> ',allItems)

            // Filtra os itens baseado no intervalo de competências
            const startDate = moment(this.selectedCompetenciaInicial, 'MM/YYYY').startOf('month');
            const endDate = moment(this.selectedCompetenciaFinal, 'MM/YYYY').endOf('month');

            allItems = allItems.filter(item => {
                const itemDate = moment(item.competencia);
                return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
            });

            // Ordena os itens por aceite null primeiro e depois por competência decrescente
            allItems.sort((a, b) => {
                // Coloca itens com aceite null no início
                if (a.aceite === null && b.aceite !== null) return -1;
                if (a.aceite !== null && b.aceite === null) return 1;

                // Depois ordena por competência decrescente
                const competenciaA = moment(a.competencia);
                const competenciaB = moment(b.competencia);
                return competenciaB.diff(competenciaA);
            });
            // Contar notas fiscais com aceite = null e atualizar um estado/data property
            this.notasFiscaisNaoAnalisadas = allItems.filter(item => item.aceite === null).length; 
            this.items = allItems;
        } catch (error) {
            console.error("Erro ao obter notas fiscais:", error);
            this.$toast.error(`Erro ao obter notas fiscais. ${error}`);
        } finally {
            this.loading = false;
        }
    },

      // Salvar as alterações em ACEITE e OBS
        async save() {
            this.loading = true;
            try {
                const data = {
                aceite: this.editedItem.aceite,
                obs: this.editedItem.obs
                };

                // Enviar a requisição para salvar as alterações no backend
                await api.put(`notas_fiscais/nota_fiscal/${this.editedItem.id}/`, data);

                // Se a requisição for bem-sucedida, atualizar os dados na tabela
                this.items = this.items.map((item) => {
                if (item.id === this.editedItem.id) {
                    return { ...item, ...this.editedItem };
                }
                return item;
                });
                this.$toast.success('Analise feita com Sucesso!');
                this.close();
                this.get_NotasFiscais();
            } catch (error) {
                console.log(error);
                this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
            }
            this.loading = false;
        },

    //   Fazer o Download dos arquivos PDF e XML
         async downloadDocumento(arquivo) {
            this.loading = true;
            try {
                let url = arquivo.replace('http', 'https');
                const response = await fetch(url);
                const blob = await response.blob();
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute('download', '');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectUrl);
            } catch (error) {
                this.$toast.error(`Erro ao baixar o arquivo. ${error}`);
            }
            this.loading = false;
        },
    async atualizar() {
        await this.loadCompetencias();
        this.get_NotasFiscais();
    },
      // Método para transformar número em valor padrão REAL
      formatCurrency(value) {
      return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      },
      // Método opara apresentar apenas o mês e ano 
      formatCompetencia(value) {
          return moment(value).format('MM/YYYY');
      },
      // Subsutituir o false e true por Não ou Sim
      formatAntecipacao(antecipacao) {
          return antecipacao ? 'Sim' : 'Não';
      },
      getDownloadLink(arquivo) {
          return `notas_fiscais/notas_fiscais/${arquivo}`;
      },
      editItem(item) {
          this.editedItem = { ...item };
          this.dialog = true;
      },
      close() {
          this.dialog = false;
          this.editedItem = {
          aceite: '',
          obs: '',
          };
      },
      confirmSave() {
          this.confirmDialog = true;
      },
      cancelSave() {
          this.confirmDialog = false;
      },
      clearData() {
        this.search = '';
        this.selectedCompetenciaInicial = null;
        this.selectedCompetenciaFinal = null;
        this.items = [];
    },
     exportToExcel() {
      try {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'NotasFiscais');

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'notasfiscais.xlsx');
      } catch (error) {
        console.error('Erro ao exportar para Excel:', error);
      }
    },
  },
  mounted() {
    this.loadCompetencias();    
  },
  watch: {
    selectedCompetenciaFinal(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.get_NotasFiscais();
        }
    },
    selectedCompetenciaInicial(newVal, oldVal) {
        if (newVal !== oldVal) {
            this.get_NotasFiscais();
        }
    },
},

}

</script>

<style>
  .v-data-table__wrapper tbody tr:first-child td:first-child {
      border-top-left-radius: 10px;
      border-top: none;
  }
  .v-data-table__wrapper tbody tr:first-child td:last-child {
      border-top-right-radius: 10px;
  }
  .v-data-table__wrapper tbody tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
      border-bottom: none;
  }
  .v-data-table__wrapper tbody tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
  }
  .v-data-table__wrapper table > thead > tr > th {
      border-bottom: 1px solid transparent !important;
  }
.text-green {
  color: green;
}

.text-red {
  color: red;
}
.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza os elementos horizontalmente */
  flex-wrap: nowrap; /* Impede que os elementos sejam quebrados em linhas diferentes */
}

.h1 {
  margin: 0; /* Remove margens padrão para melhor alinhamento */
  flex-shrink: 0; /* Impede que o título encolha, forçando a badge a se mover para baixo */
  /* align-items: center; */
  /* background-color: blue; */
}

.badge {
  background-color: #FAAF19;
  color: #0D518F;
  border-radius: 50%;
  /* padding: 5px 10px; */
  font-size: 0.8rem;
  margin-left: 10px; /* Espaço entre o título e a badge */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  flex-shrink: 0; /* Impede que a badge encolha */
}
</style>